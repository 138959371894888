import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  LayersControl,
  FeatureGroup,
  Popup,
} from "react-leaflet";
import { useNavigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "./OSM.css";

const OSM = ({
  locationCoordinates,
  riveRreach,
  onMapChange,
  setHoveredRiverReach,
}) => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      const handleMapChange = () => {
        const bounds = map.getBounds();
        onMapChange(bounds);
      };

      map.on("moveend", handleMapChange);
      map.on("zoomend", handleMapChange);

      return () => {
        map.off("moveend", handleMapChange);
        map.off("zoomend", handleMapChange);
      };
    } else {
      setMapInitialized(false);
    }
  }, [mapRef.current, onMapChange, locationCoordinates]);

  useEffect(() => {
    if (!mapInitialized && mapRef.current) {
      setMapInitialized(true);
      const bounds = mapRef.current.getBounds();
      onMapChange(bounds);
    }
  }, [mapInitialized, onMapChange]);

  // const handlePopupClick = (riveRreach) => {
  //   navigate(`/moreinfor/${riveRreach.properties.reach_id}`, { state: { riveRreach: riveRreach } });
  // };

  const handlePopupClick = (riveRreach) => {
    setHoveredRiverReach(riveRreach);
  };

  const zoomLevel =
    locationCoordinates &&
    locationCoordinates[0] === -3.1270176202726077 &&
    locationCoordinates[1] === 16.80908203125
      ? 8
      : 8;

  return (
    <MapContainer
      ref={mapRef}
      key={locationCoordinates ? locationCoordinates.join("_") : "default"}
      center={locationCoordinates || [-3.1270176202726077, 16.80908203125]}
      zoom={zoomLevel}
      style={{ height: "100%" }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Google Satellite Map">
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            maxZoom={22}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Esri Satellite Map">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            maxZoom={17}
          />
        </LayersControl.Overlay>
      </LayersControl>
      <FeatureGroup>
        {riveRreach.map((riveRreach) =>
          riveRreach.geometry.type === "MultiLineString"
            ? riveRreach.geometry.coordinates.map((line, index) => (
                // <Polyline
                //   key={`${riveRreach._id}_line_${index}`}
                //   positions={line.map(coord => [coord[1], coord[0]])}
                //   color="blue"
                //   eventHandlers={{
                //     mouseover: (event) => {
                //       // setHoveredRiverReach(riveRreach);
                //       event.target.setStyle({ color: "red" });
                //       event.target.openPopup();
                //     },
                //     mouseout: (event) => {
                //       // setHoveredRiverReach(null);
                //       event.target.setStyle({ color: "blue" });
                //       event.target.closePopup();
                //     },
                //     click: () => {handlePopupClick(riveRreach)}
                //   }}
                // >
                <Polyline
                  key={`${riveRreach._id}_line_${index}`}
                  positions={line.map((coord) => [coord[1], coord[0]])}
                  color={riveRreach.imagedata ? "yellow" : "blue"}
                  eventHandlers={{
                    mouseover: (event) => {
                      event.target.setStyle({ color: "red" });
                      event.target.openPopup();
                    },
                    mouseout: (event) => {
                      event.target.setStyle({
                        color: riveRreach.imagedata ? "yellow" : "blue",
                      });
                      event.target.closePopup();
                    },
                    click: () => {
                      handlePopupClick(riveRreach);
                    },
                  }}
                >
                  <Popup closeButton={false}>
                    <div onClick={() => handlePopupClick(riveRreach)}>
                      <h4>River Reach Info</h4>
                      ID: {riveRreach.properties.reach_id} <br />
                      Name: {riveRreach.properties.river_name} <br />
                      Length: {riveRreach.properties.reach_len} <br />
                      Width: {riveRreach.properties.width}
                    </div>
                  </Popup>
                </Polyline>
              ))
            : null
        )}
      </FeatureGroup>
    </MapContainer>
  );
};

export default OSM;
