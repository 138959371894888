import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import dayjs from 'dayjs';
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Box from "@mui/material/Box";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function ImageDownloadModal({ id, images }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedBands, setSelectedBands] = useState({
    VH: true,
    VV: true,
    Water: true,
  });
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const initialStartYear = dayjs('2015');
  const initialEndYear = dayjs('2024');
  const [selectedYearRange, setSelectedYearRange] = useState([initialStartYear, initialEndYear]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setShowSpinner(false);
    setSelectedBands({
      VH: true,
      VV: true,
      Water: true,
    });
    setDownloadProgress(0);
    setSelectedYearRange([initialStartYear, initialEndYear]);
  };

  const handleDownload = () => {
    setShowSpinner(true);
    const zip = new JSZip();

    // Create a folder with the ID as its name
    const folder = zip.folder(id);

    // Track the number of images successfully added to the folder
    let imagesAddedCount = 0;
    let totalImagesCount = 0;

    // Iterate over selected images and add them to the folder
    for (const year in images) {
      // Convert the year string to a number
      const yearNumber = parseInt(year);
      // Check if the year is within the selected year range
      if (yearNumber >= selectedYearRange[0].year() && yearNumber <= selectedYearRange[1].year()) {
        const months = images[year];
        for (const month in months) {
          const days = months[month];
          for (const day in days) {
            const imageData = days[day];
            const imagesArray = imageData.images;
            // Iterate over each image URL in the images array
            for (const imageUrl of imagesArray) {
              // Check if the image matches the selected bands
              if (
                (selectedBands.VH && imageUrl.includes("_VH.tif")) ||
                (selectedBands.VV && imageUrl.includes("_VV.tif")) ||
                (selectedBands.Water && imageUrl.includes("_Water.tif"))
              ) {
                totalImagesCount++;
                fetch(imageUrl)
                  .then((response) => {
                    if (!response.ok) {
                      throw new Error("Network response was not ok");
                    }
                    return response.blob();
                  })
                  .then((blob) => {
                    // Extract file name from URL
                    const fileName = imageUrl.substring(
                      imageUrl.lastIndexOf("/") + 1
                    );
                    // Add the image blob to the folder in the zip archive
                    folder.file(fileName, blob);
                    imagesAddedCount++;
                    // Update download progress
                    setDownloadProgress(
                      Math.round((imagesAddedCount / totalImagesCount) * 100)
                    );
                    // Check if all images have been added
                    if (imagesAddedCount === totalImagesCount) {
                      // Generate the zip file
                      zip.generateAsync({ type: "blob" }).then((blob) => {
                        // Save the zip file with the provided ID as its name
                        saveAs(blob, `${id}.zip`);
                        setShowSpinner(false);
                        handleCloseModal();
                        // Reset checkboxes and progress value
                        setSelectedBands({
                          VH: true,
                          VV: true,
                          Water: true,
                        });
                        setDownloadProgress(0);
                        setSelectedYearRange([initialStartYear, initialEndYear]);
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Error fetching image:", error);
                    setShowSpinner(false);
                    handleCloseModal();
                    setSelectedBands({
                      VH: true,
                      VV: true,
                      Water: true,
                    });
                    setDownloadProgress(0);
                    setSelectedYearRange([initialStartYear, initialEndYear]);
                  });
              }
            }
          }
        }
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setSelectedBands({
      ...selectedBands,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <Button
        style={{ marginLeft: "10px" }}
        variant="contained"
        onClick={handleOpenModal}
      >
        Download Images
      </Button>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Download the tiff images</DialogTitle>
        <DialogContent>
          <p>Select the types of the band</p>
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedBands.VH}
                    onChange={handleCheckboxChange}
                    name="VH"
                  />
                }
                label="VH"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedBands.VV}
                    onChange={handleCheckboxChange}
                    name="VV"
                  />
                }
                label="VV"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedBands.Water}
                    onChange={handleCheckboxChange}
                    name="Water"
                  />
                }
                label="Water"
              />
            </FormGroup>
          </FormControl>
          <div>
          <p>Select the year range</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Starting Year"
                views={['year']}
                value={selectedYearRange[0]}
                onChange={year => setSelectedYearRange([year, selectedYearRange[1]])}
                minDate={dayjs('2015')}
                maxDate={selectedYearRange[1]}
              />
            </LocalizationProvider>
            <div style={{ marginLeft: '20px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ending Year"
                  views={['year']}
                  value={selectedYearRange[1]}
                  onChange={year => setSelectedYearRange([selectedYearRange[0], year])}
                  minDate={selectedYearRange[0]}
                  maxDate={dayjs('2024')}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <br/>
          {showSpinner && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgressWithLabel value={downloadProgress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownload} variant="contained">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}