import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Polyline, Marker, Popup, LayersControl } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./OSM.css";

function MainReachMap({ reachData, properties, propertyId }) {
  const [polylines, setPolylines] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPolylines = async () => {
      try {
        if (reachData && reachData.type === "MultiLineString") {
          const coordinates = reachData.coordinates;
          const polylines = coordinates.map((line) =>
            line.map((point) => [point[1], point[0]])
          );
          setPolylines(polylines);
        }
      } catch (error) {
        console.error("Error processing MultiLineString:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getPolylines();
  }, [reachData]);

  if (isLoading || polylines === null) {
    return null;
  }
 // Additional check for polylines
  if (!polylines || polylines.length === 0) {
    return null;
  }

  const handlePopupClick = (property) => {
    window.open(`https://citro-frontend-216f42a97999.herokuapp.com/homeinfor/${property._id}`);
    // window.open(`http://localhost:3001/homeinfor/${property._id}`);
  };
  

  // Calculate bounds of the polyline
  const bounds = polylines.reduce(
    (accumulator, line) =>
      line.reduce(
        (innerAccumulator, point) => innerAccumulator.extend(point),
        accumulator
      ),
    new L.LatLngBounds()
  );

  // Calculate center and zoom level based on bounds
  const center = bounds.getCenter();
  const zoom = 12;

  const squareIcon = new L.divIcon({
    className: "square-icon",
    html: '<div class="square"></div>',
  });

  const highlightedPropertyIcon = new L.divIcon({
    className: "highlightedProperty-icon",
    // html: '<div class="highlightedProperty"></div>',
  });


  return (
    <MapContainer center={center} zoom={zoom} className="main_reach_map">
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Google Satellite Map">
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            maxZoom={18}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Esri Satellite Overlay">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            maxZoom={18}
          />
        </LayersControl.Overlay>
      </LayersControl>
      {polylines.map((line, index) => (
        <React.Fragment key={index}>
          <Polyline positions={line} color="blue" />

          {properties.map((property) => (
            <Marker
              key={property._id}
              position={[property.location.latitude, property.location.longitude]}
              icon={propertyId === property._id ? highlightedPropertyIcon : squareIcon}
              eventHandlers={{
                mouseover: (e) => {
                  e.target.openPopup();
                  // e.target.setIcon(blueSquareIcon);
                },
                mouseout: (e) => {
                  // e.target.closePopup();
                  // e.target.setIcon(squareIcon);
                },
              }}
            >
              <Popup closeButton={false}>
                <div onClick={() => handlePopupClick(property)}>
                  Rs.{property.price}
                  <img
                    src={property.images[0]}
                    alt="Property"
                    style={{ width: "100%", height: "40px" }}
                  />
                </div>
              </Popup>
            </Marker>
          ))}
        </React.Fragment>
      ))}
    </MapContainer>
  );
}

export default MainReachMap;

// before adding the property data
// import React, { useState, useEffect } from "react";
// import { MapContainer, TileLayer, Polyline,LayersControl } from "react-leaflet";
// import "leaflet/dist/leaflet.css";

// function MainReachMap({ reachData }) {
//   const [polylines, setPolylines] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const getPolylines = async () => {
//       try {
//         if (reachData && reachData.type === "MultiLineString") {
//           const coordinates = reachData.coordinates;
//           const polylines = coordinates.map((line) =>
//             line.map((point) => [point[1], point[0]])
//           );
//           setPolylines(polylines);
//         }
//       } catch (error) {
//         console.error("Error processing MultiLineString:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     getPolylines();
//   }, [reachData]);

//   if (isLoading) {
//     return null;
//     // <Skeleton variant="rect" width={300} height={140} />
//   }

//   if (!polylines) {
//     return null;
//   }

//   // Calculate bounds of the polyline
//   const bounds = polylines.reduce(
//     (accumulator, line) =>
//       line.reduce(
//         (innerAccumulator, point) => innerAccumulator.extend(point),
//         accumulator
//       ),
//     new window.L.LatLngBounds()
//   );

//   // Calculate center and zoom level based on bounds
//   const center = bounds.getCenter();
//   const zoom = 11;

//   return (
//     <MapContainer center={center} zoom={zoom} style={{ height: "300px", width: "65%" }}>
//       {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
//       <LayersControl position="topright">
//         <LayersControl.BaseLayer checked name="OpenStreetMap">
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//             maxZoom={19}
//           />
//         </LayersControl.BaseLayer>
//         <LayersControl.Overlay name="Esri Satellite Overlay">
//           <TileLayer
//             url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
//             maxZoom={18}
//           />
//         </LayersControl.Overlay>
//         </LayersControl>
//       {polylines.map((line, index) => (
//         <Polyline key={index} positions={line} color="blue" />
//       ))}
//     </MapContainer>
//   );
// }

// export default MainReachMap;