import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import "./Chart.css";

const Chart = ({ data, setClickedPoint, onDayClick }) => {
  const [flattenedData, setFlattenedData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [chartHeight, setChartHeight] = useState(500);

  useEffect(() => {
    const flattenData = async (nestedData) => {
      const flattenedData = [];
      const formatedCSVData = [];

      for (const year in nestedData) {
        for (const month in nestedData[year]) {
          for (const day in nestedData[year][month]) {
            const images = nestedData[year][month][day].images;

            const waterImage = images.find((image) => image.includes("Water"));

            if (waterImage) {
              const parts = waterImage.split("_");
              const numericPart = parts[parts.length - 2];
              const pixelCount = numericPart.replace("-", ".");
              const waterPixels = parseFloat(pixelCount);

              if (!isNaN(waterPixels)) {
                try {
                  const date = `${year}-${month}-${day}`;
                  flattenedData.push({ date, waterPixels });
                  formatedCSVData.push({ day, waterPixels });
                } catch (error) {
                  console.error(
                    `Error fetching or processing Water image:`,
                    error
                  );
                }
              } else {
                console.warn(
                  `Unable to extract numeric value from Water image URL: ${waterImage}`
                );
              }
            }
          }
        }
      }

      return { flattenedData, formatedCSVData };
    };

    const fetchData = async () => {
      if (data) {
        try {
          const result = await flattenData(data);
          setFlattenedData(result.flattenedData);
          setCsvData(result.formatedCSVData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [data]);

  useEffect(() => {
    const updateChartHeight = () => {
      const screenWidth = window.innerWidth;
      const minHeight = 300; // Increased minimum height
      const maxHeight = 500; // Increased maximum height
      let newChartHeight = screenWidth * 0.7; // Increased relative height
      newChartHeight = Math.max(minHeight, Math.min(newChartHeight, maxHeight));
      setChartHeight(newChartHeight);
    };

    updateChartHeight();
    window.addEventListener("resize", updateChartHeight);

    return () => window.removeEventListener("resize", updateChartHeight);
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const day = label.split("-").slice(-3).join("-");

      return (
        <div className="custom-tooltip">
          <p>{`Date: ${day}`}</p>
          <p className="water-pixels">{`Water extent: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const formatXAxisTick = (tickItem, index, ticks) => {
    const [year, month] = tickItem.split("-");
    const monthName = new Intl.DateTimeFormat("en", { month: "long" }).format(
      new Date(`${year}-${month}-01`)
    );

    const shortMonthName = monthName.substring(0, 3);
    const numMonthsBetweenTicks = Math.floor(ticks.length / 5);

    if (index % numMonthsBetweenTicks === 0) {
      return `${shortMonthName} ${year}`;
    } else {
      return "";
    }
  };

  const handleChartClick = (event, data) => {
    if (event && event.activePayload && event.activePayload.length > 0) {
      const xValue = event.activePayload[0].payload.date;
      const day = xValue.split("-").slice(-3).join("-");
      setClickedPoint(day);
      onDayClick(xValue);
    }
  };

  const DownloadButton = () => (
    <CSVLink data={csvData} filename={"chart_data.csv"}>
      <Button variant="contained" color="success">
        Download Chart Data as CSV
      </Button>
    </CSVLink>
  );

  return (
    <>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <LineChart
          data={flattenedData}
          margin={{ top: 20, left: 10, right: 30, bottom: 20 }} // Adjusted margins
          onClick={handleChartClick}
        >
          <XAxis
            dataKey="date"
            tickFormatter={(tickItem, index) =>
              formatXAxisTick(
                tickItem,
                index,
                flattenedData.map((entry) => entry.date)
              )
            }
            label={{
              value: "Date",
              position: "insideBottom",
              offset: -10,
              fill: "#0047AB",
              fontSize: 14,
            }}
            tick={{ fontSize: 14 }}
          />
          <YAxis
            label={{
              value: "Surface Water Extent (pixels)",
              angle: -90,
              dx: -30,
              dy: 10,
              fill: "#0047AB",
              textAnchor: "middle",
              fontSize: 14,
            }}
            domain={["auto", "auto"]}
            allowDecimals
            tickCount={6}
            tick={{ fontSize: 14 }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="waterPixels"
            stroke="#0047AB"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
      <br />
      <DownloadButton />
    </>
  );
};

export default Chart;

// import React, { useEffect, useState } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
// } from "recharts";
// import { CSVLink } from "react-csv";
// import Button from "@mui/material/Button";
// import "./Chart.css";

// const Chart = ({ data, setClickedPoint, onDayClick }) => {
//   const [flattenedData, setFlattenedData] = useState([]);
//   const [csvData, setCsvData] = useState([]);
//   const [chartHeight, setChartHeight] = useState(400); // Default height

//   useEffect(() => {
//     const flattenData = async (nestedData) => {
//       const flattenedData = [];
//       const formatedCSVData = [];

//       for (const year in nestedData) {
//         for (const month in nestedData[year]) {
//           for (const day in nestedData[year][month]) {
//             const images = nestedData[year][month][day].images;

//             // Find the Water image for the current date
//             const waterImage = images.find((image) => image.includes("Water"));

//             if (waterImage) {
//               const parts = waterImage.split("_");
//               const numericPart = parts[parts.length - 2];

//               // Replace hyphen with dot and extract the numeric value
//               const pixelCount = numericPart.replace("-", ".");

//               // Extract the numeric value
//               const waterPixels = parseFloat(pixelCount);

//               if (!isNaN(waterPixels)) {
//                 // Fetch and process the Water image
//                 try {
//                   const date = `${year}-${month}-${day}`;
//                   flattenedData.push({ date, waterPixels });
//                   formatedCSVData.push({ day, waterPixels });
//                 } catch (error) {
//                   console.error(
//                     `Error fetching or processing Water image:`,
//                     error
//                   );
//                 }
//               } else {
//                 console.warn(
//                   `Unable to extract numeric value from Water image URL: ${waterImage}`
//                 );
//               }
//             }
//           }
//         }
//       }

//       return { flattenedData, formatedCSVData };
//     };

//     const fetchData = async () => {
//       if (data) {
//         try {
//           const result = await flattenData(data);
//           setFlattenedData(result.flattenedData);
//           // Update the CSV data
//           setCsvData(result.formatedCSVData);
//         } catch (error) {
//           console.error("Error fetching data:", error);
//         }
//       }
//     };

//     fetchData();
//   }, [data]);

//   useEffect(() => {
//     const updateChartHeight = () => {
//       const screenWidth = window.innerWidth;
//       const minHeight = 250;
//       const maxHeight = 400;
//       let newChartHeight = screenWidth * 0.6;
//       newChartHeight = Math.max(minHeight, Math.min(newChartHeight, maxHeight));
//       setChartHeight(newChartHeight);
//     };

//     updateChartHeight();
//     window.addEventListener("resize", updateChartHeight);

//     return () => window.removeEventListener("resize", updateChartHeight);
//   }, []);

//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active && payload && payload.length) {
//       // Display only the day part of the date
//       const day = label.split("-").slice(-3).join("-");

//       return (
//         <div className="custom-tooltip">
//           <p>{`Date: ${day}`}</p>
//           <p className="water-pixels">{`Water extent: ${payload[0].value}`}</p>
//         </div>
//       );
//     }

//     return null;
//   };

//   const formatXAxisTick = (tickItem, index, ticks) => {
//     const [year, month] = tickItem.split("-");
//     const monthName = new Intl.DateTimeFormat("en", { month: "long" }).format(
//       new Date(`${year}-${month}-01`)
//     );

//     const shortMonthName = monthName.substring(0, 3);
//     const numMonthsBetweenTicks = Math.floor(ticks.length / 5);

//     if (index % numMonthsBetweenTicks === 0) {
//       return `${shortMonthName} ${year}`;
//     } else {
//       return "";
//     }
//   };

//   const handleChartClick = (event, data) => {
//     if (event && event.activePayload && event.activePayload.length > 0) {
//       const xValue = event.activePayload[0].payload.date;
//       const day = xValue.split("-").slice(-3).join("-");
//       setClickedPoint(day);
//       onDayClick(xValue);
//     }
//   };

//   const DownloadButton = () => (
//     <CSVLink data={csvData} filename={"chart_data.csv"}>
//       <Button variant="contained" color="success">
//         Download Chart Data as CSV
//       </Button>
//     </CSVLink>
//   );

//   return (
//     <>
//       <ResponsiveContainer width="95%" height={chartHeight}>
//         <LineChart
//           data={flattenedData}
//           margin={{ top: 20, left: 5, right: 30, bottom: 10 }}
//           onClick={handleChartClick}
//         >
//           <XAxis
//             dataKey="date"
//             tickFormatter={(tickItem, index) =>
//               formatXAxisTick(
//                 tickItem,
//                 index,
//                 flattenedData.map((entry) => entry.date)
//               )
//             }
//             label={{
//               value: "Date",
//               position: "insideBottom",
//               offset: -5,
//               fill: "#0047AB",
//             }}
//             tick={{ fontSize: 13 }}
//           />
//           <YAxis
//             label={{
//               value: "Surface Water Extent (pixels)",
//               angle: -90,
//               dx: -26,
//               dy: 10,
//               fill: "#0047AB",
//               textAnchor: "middle",
//             }}
//             domain={["auto", "auto"]}
//             allowDecimals
//             tickCount={5}
//             tick={{ fontSize: 13 }}
//           />
//           <CartesianGrid strokeDasharray="3 3" />
//           <Tooltip content={<CustomTooltip />} />
//           <Line type="monotone" dataKey="waterPixels" stroke="#0047AB" />
//         </LineChart>
//       </ResponsiveContainer>
//       <br />
//       <DownloadButton />
//     </>
//   );
// };

// export default Chart;
