// ReachMap.js
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Skeleton from "@mui/material/Skeleton";

function ReachMap({ reachData }) {
  const [polylines, setPolylines] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPolylines = async () => {
      try {
        if (reachData && reachData.type === "MultiLineString") {
          const coordinates = reachData.coordinates;
          const polylines = coordinates.map((line) =>
            line.map((point) => [point[1], point[0]])
          );
          setPolylines(polylines);
        }
      } catch (error) {
        console.error("Error processing MultiLineString:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getPolylines();
  }, [reachData]);

  if (isLoading) {
    return <Skeleton variant="rect" width={200} height={140} />;
  }

  if (!polylines) {
    return null;
  }

  return (
    <MapContainer center={polylines[0][0]} zoom={10} style={{ height: "163px", width: "200px" }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {polylines.map((line, index) => (
        <Polyline key={index} positions={line} color="blue" />
      ))}
    </MapContainer>
  );
}

export default ReachMap;