import React, { useState, useEffect } from "react";
import Axios from "axios";
import OSM from "../map/OSM";
import RiverReaches from "./RiverReaches";
import "./RiverDashboard.css";

const API = process.env.REACT_APP_API;

const initialBounds = {
  // northEast: { lat: 3.0856655287215506, lng: 20.104980468750004 },
  // southWest: { lat: -4.926778627933801, lng: 11.25 },
  northEast: {lat: -1.1771075151796075, lng: 18.665771484375004},
  southWest:{lat: -5.18012855719638, lng: 14.23828125},
};

const RiverDashboard = () => {
  const [riveRreach, setRiverReach] = useState([]);
  const [mapBounds, setMapBounds] = useState(initialBounds);
  const defaultCoordinates = [-3.1270176202726077, 16.80908203125];
  const [searchedLocationCoordinates, setSearchedLocationCoordinates] = useState(defaultCoordinates);
  const [hoveredRiverReach, setHoveredRiverReach] = useState(null);

  useEffect(() => {
    const fetchDataWithinBounds = async () => {
      try {
        const { northEast, southWest } = mapBounds;

        const res = await Axios.post(`${API}/api/v1/riverreach/searchByBounds`, {
          northEast: [northEast.lat, northEast.lng],
          southWest: [southWest.lat, southWest.lng],
        });

        if (res.data.success) {
          setRiverReach(res.data.data);
        } else {
          setRiverReach([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataWithinBounds();
  }, [mapBounds]);

  const onMapChange = (bounds) => {
    const { _northEast, _southWest } = bounds;
    const northEast = { lat: _northEast.lat, lng: _northEast.lng };
    const southWest = { lat: _southWest.lat, lng: _southWest.lng };

    setMapBounds({ northEast, southWest });
  };

  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="map-container">
          <OSM locationCoordinates={searchedLocationCoordinates} riveRreach={riveRreach} onMapChange={onMapChange} setHoveredRiverReach={setHoveredRiverReach} />
        </div>
        <div className="results-container">
          <RiverReaches riveRreach={riveRreach} setRiverReach={setRiverReach} hoveredRiverReach={hoveredRiverReach}/>
        </div>
      </div>
    </div>
  );
};

export default RiverDashboard;