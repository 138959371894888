// import React, { useState, useEffect } from "react";
// import Axios from "axios";
// import { useParams, useLocation } from "react-router-dom";
// import Typography from "@mui/material/Typography";
// import Skeleton from "@mui/material/Skeleton";
// import MainReachMap from "../map/MainReachMap";
// import DayReachMap from "../map/DayReachMap";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import Chart from "../chart/Chart";
// import "./ReachInformation.css";
// import ImageDownloadModal from "./ImageDownloadModal";

// const API = process.env.REACT_APP_API;

// export default function ReachInformation() {
//   const { riverId } = useParams();
//   const query = useLocation();
//   const searchParams = new URLSearchParams(query.search);
//   const propertyId = searchParams.get("propertyId");

//   const [reachDetails, setReachDetails] = useState(null);
//   const [properties, setProperties] = useState(null);
//   const [clickedPoint, setClickedPoint] = useState(null);
//   const [expanded, setExpanded] = useState([]);

//   useEffect(() => {
//     Axios.get(`${API}/api/v1/riverreach/details/${riverId}`)
//       .then((response) => {
//         setReachDetails(response.data.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     Axios.get(`${API}/api/v1/property/${riverId}`)
//       .then((response) => {
//         setProperties(response.data.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, [riverId]);

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpanded((prevExpanded) => {
//       if (isExpanded) {
//         return [...prevExpanded, panel];
//       } else {
//         return prevExpanded.filter((item) => item !== panel);
//       }
//     });
//   };

//   const handleDayClick = (clickedDay) => {
//     const [year, month] = clickedDay.split("-");
//     const yearKey = `${year}`;
//     const monthKey = `${year}-${month}`;

//     setExpanded((prevExpanded) => {
//       if (prevExpanded.includes(yearKey)) {
//         return [...prevExpanded, monthKey];
//       } else {
//         return [...prevExpanded, yearKey, monthKey];
//       }
//     });
//   };

//   useEffect(() => {
//     const yearMonth = expanded[expanded.length - 1];
//     const element = document.getElementById(yearMonth);
//     if (element) {
//       console.log(element.id);
//       setTimeout(() => {
//         element.scrollIntoView({ behavior: "auto", block: "center" });
//       }, 1000);
//     }
//   }, [expanded]);

//   if (!reachDetails) {
//     return (
//       <div style={{ padding: "20px" }}>
//         <Skeleton variant="rectangular" width="100%" height={800} />
//       </div>
//     );
//   }

//   // Validate date values in reachDetails.imagedata
//   const validateDate = (date) => {
//     return !isNaN(new Date(date).getTime());
//   };

//   return (
//     <>
//       <div className="main__box">
//         {properties ? (
//           <MainReachMap
//             reachData={reachDetails.geometry}
//             properties={properties}
//             propertyId={propertyId}
//           />
//         ) : (
//           <div>
//             <Skeleton variant="rectangular" width="100%" height={800} />
//           </div>
//         )}
//         <Typography variant="body1" gutterBottom>
//           <h3 className="details__container">Reach Info</h3>
//           <div className="details__container">
//             ID: {reachDetails.properties?.reach_id} <br />
//             Reach Name: {reachDetails.properties?.river_name} <br />
//             Length: {reachDetails.properties?.reach_len} <br />
//             Width: {reachDetails.properties?.width} <br />
//             WSE: {reachDetails.properties?.wse}
//             <br />
//             Lat: {reachDetails.properties?.y} <br />
//             Lon: {reachDetails.properties?.x}
//           </div>
//         </Typography>
//       </div>
//       {reachDetails.imagedata && (
//         <>
//           <div className="main__box">
//             <h3>Inundated Pixels</h3>
//           </div>
//           <div className="chart_box">
//             <Chart
//               data={reachDetails.imagedata}
//               setClickedPoint={setClickedPoint}
//               onDayClick={handleDayClick}
//             />
//             <ImageDownloadModal
//               id={reachDetails.properties.reach_id}
//               images={reachDetails.imagedata}
//             />
//           </div>
//           <div className="tile__box">
//             {reachDetails && reachDetails.imagedata && (
//               <>
//                 {Object.keys(reachDetails.imagedata).map((year) => (
//                   <Accordion
//                     key={year}
//                     expanded={expanded.includes(`${year}`)}
//                     onChange={handleAccordionChange(`${year}`)}
//                     className="year-section"
//                   >
//                     <AccordionSummary
//                       expandIcon={<ExpandMoreIcon className="expand-icon" />}
//                     >
//                       <Typography variant="h4">{year}</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                       {reachDetails.imagedata[year] &&
//                         Object.keys(reachDetails.imagedata[year]).map(
//                           (month) => (
//                             <div
//                               id={`${year}-${month}`}
//                               key={`${year}-${month}`}
//                             >
//                               <Accordion
//                                 key={`${year}-${month}`}
//                                 expanded={expanded.includes(`${year}-${month}`)}
//                                 onChange={handleAccordionChange(
//                                   `${year}-${month}`
//                                 )}
//                               >
//                                 <AccordionSummary
//                                   expandIcon={<ExpandMoreIcon />}
//                                 >
//                                   <Typography variant="subtitle1">
//                                     {month}
//                                   </Typography>
//                                 </AccordionSummary>
//                                 <AccordionDetails
//                                   style={{ display: "flex", flexWrap: "wrap" }}
//                                 >
//                                   {reachDetails.imagedata[year][month] &&
//                                     Object.keys(
//                                       reachDetails.imagedata[year][month]
//                                     ).map((date) => (
//                                       <div
//                                         key={date}
//                                         className="day_mapdiv"
//                                         style={{
//                                           border:
//                                             date === clickedPoint
//                                               ? "3px solid #B32020"
//                                               : "none",
//                                           background:
//                                             date === clickedPoint
//                                               ? "#B32020"
//                                               : "none",
//                                         }}
//                                       >
//                                         <Typography>{date}</Typography>
//                                         {validateDate(date) ? (
//                                           <DayReachMap
//                                             data={
//                                               reachDetails.imagedata[year][
//                                                 month
//                                               ][date]
//                                             }
//                                             reach={reachDetails}
//                                             properties={properties}
//                                             propertyId={propertyId}
//                                           />
//                                         ) : (
//                                           <div>Invalid date: {date}</div>
//                                         )}
//                                       </div>
//                                     ))}
//                                 </AccordionDetails>
//                               </Accordion>
//                             </div>
//                           )
//                         )}
//                     </AccordionDetails>
//                   </Accordion>
//                 ))}
//               </>
//             )}
//           </div>
//         </>
//       )}
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import MainReachMap from "../map/MainReachMap";
import DayReachMap from "../map/DayReachMap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Chart from "../chart/Chart";
import "./ReachInformation.css";
import ImageDownloadModal from "./ImageDownloadModal";
import { parseISO, format } from "date-fns";

const API = process.env.REACT_APP_API;

export default function ReachInformation() {
  const { riverId } = useParams();
  const query = useLocation();
  const searchParams = new URLSearchParams(query.search);
  const propertyId = searchParams.get("propertyId");

  const [reachDetails, setReachDetails] = useState(null);
  const [properties, setProperties] = useState(null);
  const [clickedPoint, setClickedPoint] = useState(null);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    Axios.get(`${API}/api/v1/riverreach/details/${riverId}`)
      .then((response) => {
        setReachDetails(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    Axios.get(`${API}/api/v1/property/${riverId}`)
      .then((response) => {
        setProperties(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [riverId]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      } else {
        return prevExpanded.filter((item) => item !== panel);
      }
    });
  };

  const handleDayClick = (clickedDay) => {
    const [year, month] = clickedDay.split("-");
    const yearKey = `${year}`;
    const monthKey = `${year}-${month}`;

    setExpanded((prevExpanded) => {
      if (prevExpanded.includes(yearKey)) {
        return [...prevExpanded, monthKey];
      } else {
        return [...prevExpanded, yearKey, monthKey];
      }
    });
  };

  useEffect(() => {
    const yearMonth = expanded[expanded.length - 1];
    const element = document.getElementById(yearMonth);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "auto", block: "center" });
      }, 1000);
    }
  }, [expanded]);

  const formatDate = (dateString) => {
    const parsedDate = parseISO(dateString);
    if (isNaN(parsedDate)) {
      console.error("Invalid date:", dateString);
      return dateString; // Return original string if parsing fails
    }
    return format(parsedDate, "MM/dd/yyyy");
  };

  if (!reachDetails) {
    return (
      <div style={{ padding: "20px" }}>
        <Skeleton variant="rectangular" width="100%" height={800} />
      </div>
    );
  }

  return (
    <>
      <div className="main__box">
        <MainReachMap
          reachData={reachDetails.geometry}
          properties={properties}
          propertyId={propertyId}
        />
        <Typography variant="body1" gutterBottom>
          <h3 className="details__container">Reach Info</h3>
          <div className="details__container">
            ID: {reachDetails.properties?.reach_id} <br />
            Reach Name: {reachDetails.properties?.river_name} <br />
            Length: {reachDetails.properties?.reach_len} <br />
            Width: {reachDetails.properties?.width} <br />
            WSE: {reachDetails.properties?.wse}
            <br />
            Lat: {reachDetails.properties?.y} <br />
            Lon: {reachDetails.properties?.x}
          </div>
        </Typography>
      </div>
      {reachDetails.imagedata && (
        <>
          <div className="main__box">
            <h3>Inundated Pixels</h3>
          </div>
          <div className="chart_box">
            <Chart
              data={reachDetails.imagedata}
              setClickedPoint={setClickedPoint}
              onDayClick={handleDayClick}
            />
            <ImageDownloadModal
              id={reachDetails.properties.reach_id}
              images={reachDetails.imagedata}
            />
          </div>
          <div className="tile__box">
            {reachDetails && reachDetails.imagedata && (
              <>
                {Object.keys(reachDetails.imagedata).map((year) => (
                  <Accordion
                    key={year}
                    expanded={expanded.includes(`${year}`)}
                    onChange={handleAccordionChange(`${year}`)}
                    className="year-section"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    >
                      <Typography variant="h4">{year}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {reachDetails.imagedata[year] &&
                        Object.keys(reachDetails.imagedata[year]).map(
                          (month) => (
                            <div
                              id={`${year}-${month}`}
                              key={`${year}-${month}`}
                            >
                              <Accordion
                                key={`${year}-${month}`}
                                expanded={expanded.includes(`${year}-${month}`)}
                                onChange={handleAccordionChange(
                                  `${year}-${month}`
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography variant="subtitle1">
                                    {month}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {reachDetails.imagedata[year][month] &&
                                    Object.keys(
                                      reachDetails.imagedata[year][month]
                                    ).map((date) => (
                                      <div
                                        key={date}
                                        className="day_mapdiv"
                                        style={{
                                          border:
                                            date === clickedPoint
                                              ? "3px solid #B32020"
                                              : "none",
                                          background:
                                            date === clickedPoint
                                              ? "#B32020"
                                              : "none",
                                        }}
                                      >
                                        <Typography>
                                          {formatDate(date)}
                                        </Typography>
                                        <DayReachMap
                                          data={
                                            reachDetails.imagedata[year][month][
                                              date
                                            ]
                                          }
                                          reach={reachDetails}
                                          properties={properties}
                                          propertyId={propertyId}
                                        />
                                      </div>
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )
                        )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

// import React, { useState, useEffect } from "react";
// import Axios from "axios";
// import { useParams, useLocation } from "react-router-dom";
// import Typography from "@mui/material/Typography";
// import Skeleton from "@mui/material/Skeleton";
// import MainReachMap from "../map/MainReachMap";
// import DayReachMap from "../map/DayReachMap";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import Chart from "../chart/Chart";
// import "./ReachInformation.css";
// import ImageDownloadModal from "./ImageDownloadModal";

// const API = process.env.REACT_APP_API;

// export default function ReachInformation() {
//   const { riverId } = useParams();
//   const query = useLocation();
//   const searchParams = new URLSearchParams(query.search);
//   const propertyId = searchParams.get("propertyId");

//   const [reachDetails, setReachDetails] = useState(null);
//   const [properties, setProperties] = useState(null);
//   const [clickedPoint, setClickedPoint] = useState(null);
//   const [expanded, setExpanded] = useState([]);

//   useEffect(() => {
//     Axios.get(`${API}/api/v1/riverreach/details/${riverId}`)
//       .then((response) => {
//         setReachDetails(response.data.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     Axios.get(`${API}/api/v1/property/${riverId}`)
//       .then((response) => {
//         setProperties(response.data.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, [riverId]);

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpanded((prevExpanded) => {
//       if (isExpanded) {
//         return [...prevExpanded, panel];
//       } else {
//         return prevExpanded.filter((item) => item !== panel);
//       }
//     });
//   };

//   const handleDayClick = (clickedDay) => {
//     const [year, month] = clickedDay.split("-");
//     const yearKey = `${year}`;
//     const monthKey = `${year}-${month}`;

//     setExpanded((prevExpanded) => {
//       if (prevExpanded.includes(yearKey)) {
//         return [...prevExpanded, monthKey];
//       } else {
//         return [...prevExpanded, yearKey, monthKey];
//       }
//     });
//   };

//   useEffect(() => {
//     const yearMonth = expanded[expanded.length - 1];
//     const element = document.getElementById(yearMonth);
//     if (element) {
//       console.log(element.id);
//       setTimeout(() => {
//         element.scrollIntoView({ behavior: "auto", block: "center" });
//       }, 1000);
//     }
//   }, [expanded]);

//   if (!reachDetails) {
//     return (
//       <div style={{ padding: "20px" }}>
//         <Skeleton variant="rectangular" width="100%" height={800} />
//       </div>
//     );
//   }

//   return (
//     <>
//       <div className="main__box">
//         <MainReachMap
//           reachData={reachDetails.geometry}
//           properties={properties}
//           propertyId={propertyId}
//         />
//         <Typography variant="body1" gutterBottom>
//           <h3 className="details__container">Reach Info</h3>
//           <div className="details__container">
//             ID: {reachDetails.properties?.reach_id} <br />
//             Reach Name: {reachDetails.properties?.river_name} <br />
//             Length: {reachDetails.properties?.reach_len} <br />
//             Width: {reachDetails.properties?.width} <br />
//             WSE: {reachDetails.properties?.wse}
//             <br />
//             Lat: {reachDetails.properties?.y} <br />
//             Lon: {reachDetails.properties?.x}
//           </div>
//         </Typography>
//       </div>
//       {reachDetails.imagedata && (
//         <>
//           <div className="main__box">
//             <h3>Inundated Pixels</h3>
//           </div>
//           <div className="chart_box">
//             <Chart
//               data={reachDetails.imagedata}
//               setClickedPoint={setClickedPoint}
//               onDayClick={handleDayClick}
//             />
//             <ImageDownloadModal
//               id={reachDetails.properties.reach_id}
//               images={reachDetails.imagedata}
//             />
//           </div>
//           <div className="tile__box">
//             {reachDetails && reachDetails.imagedata && (
//               <>
//                 {Object.keys(reachDetails.imagedata).map((year) => (
//                   <Accordion
//                     key={year}
//                     expanded={expanded.includes(`${year}`)}
//                     onChange={handleAccordionChange(`${year}`)}
//                     className="year-section"
//                   >
//                     <AccordionSummary
//                       expandIcon={<ExpandMoreIcon className="expand-icon" />}
//                     >
//                       <Typography variant="h4">{year}</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                       {reachDetails.imagedata[year] &&
//                         Object.keys(reachDetails.imagedata[year]).map(
//                           (month) => (
//                             <div
//                               id={`${year}-${month}`}
//                               key={`${year}-${month}`}
//                             >
//                               <Accordion
//                                 key={`${year}-${month}`}
//                                 expanded={expanded.includes(`${year}-${month}`)}
//                                 onChange={handleAccordionChange(
//                                   `${year}-${month}`
//                                 )}
//                               >
//                                 <AccordionSummary
//                                   expandIcon={<ExpandMoreIcon />}
//                                 >
//                                   <Typography variant="subtitle1">
//                                     {month}
//                                   </Typography>
//                                 </AccordionSummary>
//                                 <AccordionDetails
//                                   style={{ display: "flex", flexWrap: "wrap" }}
//                                 >
//                                   {reachDetails.imagedata[year][month] &&
//                                     Object.keys(
//                                       reachDetails.imagedata[year][month]
//                                     ).map((date) => (
//                                       <div
//                                         key={date}
//                                         className="day_mapdiv"
//                                         style={{
//                                           border:
//                                             date === clickedPoint
//                                               ? "3px solid #B32020"
//                                               : "none",
//                                           background:
//                                             date === clickedPoint
//                                               ? "#B32020"
//                                               : "none",
//                                         }}
//                                       >
//                                         <Typography>{date}</Typography>
//                                         <DayReachMap
//                                           data={
//                                             reachDetails.imagedata[year][month][
//                                               date
//                                             ]
//                                           }
//                                           reach={reachDetails}
//                                           properties={properties}
//                                           propertyId={propertyId}
//                                         />
//                                       </div>
//                                     ))}
//                                 </AccordionDetails>
//                               </Accordion>
//                             </div>
//                           )
//                         )}
//                     </AccordionDetails>
//                   </Accordion>
//                 ))}
//               </>
//             )}
//           </div>
//         </>
//       )}
//     </>
//   );
// }
