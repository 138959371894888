// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-header {
    background-color: #051651;
    padding: 20px;
    text-align: left;
  }
  
  .navbar__link {
    text-decoration: none !important;
    color: white;
  }
  
  .navbar__link h1 {
    margin: 0;
    font-size: 1.5rem;
    color: white; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/common/NavBar/NavBarStyles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,gCAAgC;IAChC,YAAY;EACd;;EAEA;IACE,SAAS;IACT,iBAAiB;IACjB,YAAY;EACd","sourcesContent":[".site-header {\n    background-color: #051651;\n    padding: 20px;\n    text-align: left;\n  }\n  \n  .navbar__link {\n    text-decoration: none !important;\n    color: white;\n  }\n  \n  .navbar__link h1 {\n    margin: 0;\n    font-size: 1.5rem;\n    color: white; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
