import React from "react";
import { Link } from "react-router-dom";
import './NavBarStyles.css';

export default function NavBar() {
  return (
    <header className="site-header">
      <div>
        <Link to="/" className="navbar__link">
          <h1>Fluvisat.com</h1>
        </Link>
      </div>
    </header>
  );
}

// import React from "react";
// import { AppBar } from "@mui/material";
// import './NavBarStyles.css';

// export default function NavBar() {

//   return (
//     <div>
//       <AppBar sx={{backgroundColor: '#F9F9F9', boxShadow: 'none'}} className="navbar" position="static">
//               <p sx={{ flexGrow: 1 }} className="title">Fluvisat</p>
//       </AppBar>
//     </div>
//   );
// }