import React, { useEffect, useState } from "react";
import axios from "axios";
import MediaCard from "../common/MediaCard";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import "./RiverReaches.css";

const API = process.env.REACT_APP_API;
const ITEMS_PER_PAGE = 8;

export default function RiverReaches({ riveRreach, setRiverReach, hoveredRiverReach }) {
  
  const [loading, setLoading] = useState(true);  
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    defaultSearch();
  }, []);

  const defaultSearch = async () => {
    try {
      const northEast= [-1.1771075151796075, 18.665771484375004];
      const southWest= [-5.18012855719638, 14.23828125];

      const res = await axios.post(`${API}/api/v1/riverreach/searchByBounds`, {
        northEast,
        southWest,
      });

      if (res.data.success) {
        setRiverReach(res.data.data);
      } else {
        setRiverReach([]);
      }
    } catch (error) {
      console.log("error01", error);
      setRiverReach([]);
    } finally {
      setLoading(false);
    }
  }; 
  
  // Calculate the total number of pages
  const totalPages = riveRreach ? Math.ceil(riveRreach.length / ITEMS_PER_PAGE) : 0;

  // Get the riveRreach for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const riveRreachForCurrentPage = riveRreach
    ? hoveredRiverReach
      ? [hoveredRiverReach, ...riveRreach.filter(item => item !== hoveredRiverReach)].slice(startIndex, endIndex)
      : riveRreach.slice(startIndex, endIndex)
    : [];

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="search-results-container">
      {loading ? (
       <div className="SR_Estate_component">
       {Array.from(new Array(ITEMS_PER_PAGE)).map((_, index) => (
         <div key={index} className="SR_Card_wrapper">
           <Skeleton variant="rectangular" width={340} height={160} />
         </div>
       ))}
     </div>
      ) : (
        <div className="SR_Estate_component">
          {riveRreachForCurrentPage.length === 0 ? (
            <div style={{ display: 'flex',flexDirection: 'column', height: '60vh'}}>
              <h1>SWORD Congo River Basin</h1>
              <br/>
              <h3>No matching results. Try modifying your search.</h3>
            </div>
          ) : (
            riveRreachForCurrentPage.map((riveRreach, index) => (
              <div key={riveRreach?._id?.toString()} className={`SR_Card_wrapper ${index === 0 && hoveredRiverReach ? 'highlighted-card' : ''}`}>
                <MediaCard riveRreachDetails={riveRreach} />  
              </div>
            ))
          )}
        </div>
      )}

      {totalPages > 1 && (
        <div className="pagination__container">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              color="primary"
              onChange={handlePageChange}
              size="medium"
            />
          </Stack>
        </div>
      )}
    </div>
  );
}