// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgcolor {
    background-color: #F27A7A;
  }
  /* #F7F7F7 */
  .container {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content-wrapper {
    display: flex;
    flex-direction: row;
    flex: auto;
  }
  
  .map-container {
    flex: auto;
    height: 95%;
    position: relative;
  }
  
  .results-container {
    width: 730px;
    overflow-y: auto;
    height: 100vh;
  }
  
  .leaflet-top.leaflet-right {
    top: 10px;
    right: 10px;
  }
  
  @media screen and (max-width: 600px) {
    .container {
      height: auto;
      overflow-y: auto;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .content-wrapper {
      flex-direction: column;
    }
  
    .map-container,
    .results-container {
      width: 100%;
      max-width: none;
    }
    .results-container {
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/river_reaches/RiverDashboard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;EACA,YAAY;EACZ;IACE,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,SAAS;IACT,WAAW;EACb;;EAEA;IACE;MACE,YAAY;MACZ,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,sBAAsB;IACxB;;IAEA;;MAEE,WAAW;MACX,eAAe;IACjB;IACA;MACE,eAAe;MACf,aAAa;MACb,uBAAuB;MACvB,mBAAmB;MACnB,sBAAsB;IACxB;EACF","sourcesContent":[".bgcolor {\n    background-color: #F27A7A;\n  }\n  /* #F7F7F7 */\n  .container {\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n  }\n  \n  .content-wrapper {\n    display: flex;\n    flex-direction: row;\n    flex: auto;\n  }\n  \n  .map-container {\n    flex: auto;\n    height: 95%;\n    position: relative;\n  }\n  \n  .results-container {\n    width: 730px;\n    overflow-y: auto;\n    height: 100vh;\n  }\n  \n  .leaflet-top.leaflet-right {\n    top: 10px;\n    right: 10px;\n  }\n  \n  @media screen and (max-width: 600px) {\n    .container {\n      height: auto;\n      overflow-y: auto;\n    }\n  }\n  \n  @media screen and (max-width: 1100px) {\n    .content-wrapper {\n      flex-direction: column;\n    }\n  \n    .map-container,\n    .results-container {\n      width: 100%;\n      max-width: none;\n    }\n    .results-container {\n      margin-top: 5px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
