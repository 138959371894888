// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    transition: box-shadow 0.5s;
  }
  
  .custom-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .propertyD-card {
    width: 337px;
    height: 161px;
    transition: box-shadow 0.5s;
    background-color: black !important;
    color: white !important;
  }
  
  .propertyD-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 1);
  }`, "",{"version":3,"sources":["webpack://./src/components/common/StyleCard.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,kCAAkC;IAClC,uBAAuB;EACzB;;EAEA;IACE,wCAAwC;EAC1C","sourcesContent":[".custom-card {\n    transition: box-shadow 0.5s;\n  }\n  \n  .custom-card:hover {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);\n  }\n  \n  .propertyD-card {\n    width: 337px;\n    height: 161px;\n    transition: box-shadow 0.5s;\n    background-color: black !important;\n    color: white !important;\n  }\n  \n  .propertyD-card:hover {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 1);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
