import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import RiverDashboard from "./components/river_reaches/RiverDashboard";
import ReachInformation from "./components/river_reaches/ReachInformation";
import NavBar from "./components/common/NavBar/NavBar";
import Footer from "./components/common/Footer/Footer";

function App() {
  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<RiverDashboard />} />
          <Route path="/moreinfor/:riverId" element={<ReachInformation />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}


export default App;