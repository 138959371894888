import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "./StyleCard.css";
import ReachMap from "../map/ReachMap";

export default function MediaCard({ riveRreachDetails }) {
  const navigate = useNavigate();
  // const handleCardClick = () => {
  //   navigate(`/moreinfor/${riveRreachDetails._id}`, { state: { riveRreach: riveRreachDetails } });
  // };
  const handleCardClick = () => {
    navigate(`/moreinfor/${riveRreachDetails.properties.reach_id}`, {
      state: { riveRreach: riveRreachDetails },
    });
  };

  return (
    <Card
      className="propertyD-card"
      onClick={handleCardClick}
      style={{ cursor: "pointer", margin: 0, padding: 0 }}
    >
      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: 0,
          padding: 0,
        }}
      >
        <ReachMap
          reachData={riveRreachDetails.geometry}
          style={{ margin: 0, padding: 0 }}
        />

        <Typography
          gutterBottom
          sx={{ fontSize: "12px", fontWeight: 550, margin: 0, padding: 0 }}
        >
          ID: {riveRreachDetails.properties.reach_id} <br />
          Name: {riveRreachDetails.properties.river_name} <br />
          Length: {riveRreachDetails.properties.reach_len.toFixed(3)} <br />
          Width: {riveRreachDetails.properties.width}
        </Typography>
      </CardContent>
    </Card>
  );
}
