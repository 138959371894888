import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import parseGeoraster from 'georaster';
import GeoRasterLayer from "georaster-layer-for-leaflet";
import "./OSM.css";

function DayReachMap({ data,properties,propertyId }) {
  const mapRef = useRef();
  // const [selectedOverlay, setSelectedOverlay] = useState(2);
  const [selectedOverlay, setSelectedOverlay] = useState(data.images.length === 3 ? 2 : 1);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      map.eachLayer((existingLayer) => {
        if (existingLayer instanceof GeoRasterLayer) {
          map.removeLayer(existingLayer);
        }
      });

      const loadGeoTIFF = async () => {
        try {
          const imageUrl = data.images[selectedOverlay];

          const response = await fetch(imageUrl);
          const arrayBuffer = await response.arrayBuffer();

          // Parse the GeoTIFF using the georaster library
          const georaster = await parseGeoraster(arrayBuffer);
          // console.log("georaster:",georaster);

        //   var layer = new GeoRasterLayer({
        //     georaster: georaster,
        //     opacity: 0.7,
        //     resolution: 512
        // });

          const layer = new GeoRasterLayer({
            georaster: georaster,
            opacity: 1,
            resolution: 512,
            pixelValuesToColorFn: values => {
              const elevationValue = values[0];

              if (elevationValue === null || isNaN(elevationValue)) {
                return 'rgba(0, 0, 0, 0)'; // Make NoData pixels transparent
              }

              if (imageUrl.includes("Water")) {
                if (elevationValue === 0) {
                  return 'rgba(0, 0, 0, 0)';
                } else {
                  return 'rgba(0, 0, 255, 1)';
                }
              } else {
                // return 'rgba(0, 0, 255, 1)';
                const normalizedValue = (elevationValue + 50) / 100 * 255;
                return `rgba(${normalizedValue}, ${normalizedValue}, ${normalizedValue}, 1)`;
              } 
            }
            // if (imageUrl.includes("Water")) {
            //     if (elevationValue === 0) {
            //       return 'rgba(0, 0, 0, 0)';
            //     } else {
            //       return 'rgba(0, 0, 255, 1)';
            //     }
            //   } else if(imageUrl.includes("VV")) {
            //     if (elevationValue >= -21) {
            //       return 'rgba(0, 0, 0, 0)';
            //     } else {
            //       return 'rgba(255, 0, 0, 1)';
            //     }
            //   }else{
            //     const normalizedValue = (elevationValue + 50) / 100 * 255;
            //     return `rgba(${normalizedValue}, ${normalizedValue}, ${normalizedValue}, 1)`;
            //   }
            // }
            
          });

          // Add the GeoRasterLayer to the map
          // layer.addTo(map);
          layer.addTo(map).setZIndex(1000);

          // Get the bounding box from the GeoRasterLayer
          const bounds = layer.getBounds();

          // Center the map to the bounding box
          map.fitBounds(bounds);

          // Add Markers for each property on the map

          properties.forEach((property, index) => {
            const isHighlighted = property._id === propertyId;

            const markerIcon = isHighlighted ? highlightedPropertyIcon : squareIcon;

            const marker = L.marker([property.location.latitude, property.location.longitude], { icon: markerIcon })
              .addTo(map)
              .bindPopup(`
                <div>
                  <p>Rs.${property.price}</p>
                  <p>${property.address}</p>
                </div>
              `);
          
            marker.on("mouseover", () => {
              marker.openPopup();
            });
          
            marker.on("mouseout", () => {
              marker.closePopup();
            });
          
            marker.on("click", () => {
              window.open(`https://citro-frontend-216f42a97999.herokuapp.com/homeinfor/${property._id}`);
              // window.open(`http://localhost:3001/homeinfor/${property._id}`);
            });
          });          
          
          map.on('click', (e) => {
            const latlng = e.latlng;
            const popupContent = `
              <div>
                <h4>River Reach Info</h4>
                Width: ${data.details.width} <br />
                Discharge: ${data.details.discharge} <br />
                wse: ${data.details.wse}
              </div>
            `;

            L.popup()
              .setLatLng(latlng)
              .setContent(popupContent)
              .openOn(map);
          });

          map.on('mouseout', (e) => {
            e.target.closePopup();
          })
        } catch (error) {
          // console.error('Error loading GeoTIFF image:', error);
          console.warn('Attempted to Load Geotiff ')
        }
      };

      loadGeoTIFF();
    }
  }, [selectedOverlay, data.images, data.details]);

  const handleOverlayChange = (event) => {
    setSelectedOverlay(parseInt(event.target.value, 10));
  };

  const squareIcon = new L.divIcon({
    className: "square-icon",
    html: '<div class="square"></div>',
  });
  const highlightedPropertyIcon = new L.divIcon({
    className: "highlightedProperty-small-icon",
  });

  return (
    <div>
      <MapContainer className="day_map" ref={mapRef}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LayersControl position="topright">
              <LayersControl.Overlay name="Google Satellite Map">
                <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
              </LayersControl.Overlay>
              <LayersControl.Overlay name="Esri Satellite Map">
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
              </LayersControl.Overlay>
            </LayersControl>
      </MapContainer>
    <div>
    {data.images.map((imageUrl, index) => {
      let label = "Unknown"; // Default label

      if (imageUrl.includes("VV")) {
        label = "VV";
      } else if (imageUrl.includes("VH")) {
        label = "VH";
      } else if (imageUrl.includes("Water")) {
        label = "Water";
      }

      return (
        <label key={index}>
          <input
            type="radio"
            value={index}
            checked={index === selectedOverlay}
            onChange={handleOverlayChange}
          />
          {` ${label}`}
        </label>
      );
    })}
  </div>
</div>

  );
}

export default DayReachMap;

// import React, { useEffect, useRef, useState } from "react";
// import { MapContainer, TileLayer, LayersControl, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from 'leaflet';
// import { fromUrl,fromArrayBuffer } from 'geotiff';

// function DayReachMap({ data, properties, propertyId }) {
//   const mapRef = useRef();
//   const [selectedOverlay, setSelectedOverlay] = useState(0);
//   const [geoTIFFImage, setGeoTIFFImage] = useState(null);
//   const [bounds, setBounds] = useState(null);

//   useEffect(() => {
//     const map = mapRef.current;

//     if (map && geoTIFFImage && bounds) {
//       map.eachLayer((existingLayer) => {
//         if (existingLayer instanceof L.TileLayer || existingLayer instanceof L.GridLayer) {
//           map.removeLayer(existingLayer);
//         }
//       });

//       const width = geoTIFFImage.getWidth();
//       const height = geoTIFFImage.getHeight();
//       const boundsArray = [
//         [bounds[1], bounds[0]],
//         [bounds[3], bounds[2]]
//       ];

//       const tiffLayer = L.gridLayer({
//         bounds: boundsArray,
//         maxZoom: 18,
//         tileSize: 512,
//         updateWhenIdle: true
//       });

//       tiffLayer.createTile = function (coords) {
//         const tile = L.DomUtil.create('canvas', 'leaflet-tile');
//         const ctx = tile.getContext('2d');
//         const imgData = ctx.createImageData(512, 512);

//         const window = [
//           coords.y * 512,
//           coords.x * 512,
//           Math.min(width - coords.y * 512, 512),
//           Math.min(height - coords.x * 512, 512)
//         ];

//         const raster = geoTIFFImage.readRasters({ window });

//         // Manipulate raster data as needed to render on canvas
//         // Example: render raster data to imgData

//         // Render imgData to canvas
//         ctx.putImageData(imgData, 0, 0);

//         return tile;
//       };

//       tiffLayer.addTo(map);
//     }
//   }, [geoTIFFImage, bounds]);

//   useEffect(() => {
//     const loadGeoTIFF = async () => {
//       try {
//         const imageUrl = data.images[selectedOverlay];
//         console.log(imageUrl);
//         const response = await fetch(imageUrl);
//         // console.log("response:",response);
//         // const arrayBuffer = await response.arrayBuffer();
//         // console.log("arrayBuffer:",arrayBuffer);

//         // Parse the GeoTIFF using the geotiff library
//         // const tiff = await fromArrayBuffer(arrayBuffer);
//         const tiff = await fromUrl(imageUrl);
//         console.log(tiff);
//         const image = await tiff.getImage();
//         console.log(image)

//         const tiePoints = image.getTiePoints()[0];
//         const pixelSize = image.getFileDirectory().ModelPixelScale;
//         const width = image.getWidth();
//         const height = image.getHeight();

//         // Calculate bounding box
//         const topLeftLon = tiePoints.x;
//         const topLeftLat = tiePoints.y;
//         const bottomRightLon = topLeftLon + (width * pixelSize[0]);
//         const bottomRightLat = topLeftLat + (height * pixelSize[1]);

//         setBounds([topLeftLon, topLeftLat, bottomRightLon, bottomRightLat]);
//         setGeoTIFFImage(image);
//       } catch (error) {
//         console.warn('Attempted to Load Geotiff ');
//       }
//     };

//     loadGeoTIFF();
//   }, [selectedOverlay, data.images]);

//   useEffect(() => {
//     const map = mapRef.current;

//     // Check if map and properties are available
//     if (map && properties) {
//       // Loop through each property to add markers
//       properties.forEach((property, index) => {
//         const isHighlighted = property._id === propertyId;
//         const markerIcon = isHighlighted ? highlightedPropertyIcon : squareIcon;

//         const marker = L.marker([property.location.latitude, property.location.longitude], { icon: markerIcon })
//           .addTo(map)
//           .bindPopup(`
//             <div>
//               <p>Rs.${property.price}</p>
//               <p>${property.address}</p>
//             </div>
//           `);

//         marker.on("mouseover", () => {
//           marker.openPopup();
//         });

//         marker.on("mouseout", () => {
//           marker.closePopup();
//         });

//         marker.on("click", () => {
//           window.open(`https://citro-frontend-216f42a97999.herokuapp.com/homeinfor/${property._id}`);
//           // window.open(`http://localhost:3001/homeinfor/${property._id}`);
//         });
//       });
//     }
//   }, [properties, propertyId]);

//   const handleOverlayChange = (event) => {
//     setSelectedOverlay(parseInt(event.target.value, 10));
//   };

//   const squareIcon = new L.divIcon({
//     className: "square-icon",
//     html: '<div class="square"></div>',
//   });

//   const highlightedPropertyIcon = new L.divIcon({
//     className: "highlightedProperty-small-icon",
//   });

//   return (
//     <div>
//       <MapContainer style={{ height: "250px", width: "400px" }} ref={mapRef}>
//         <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
//         <LayersControl position="topright">
//           <LayersControl.Overlay name="Google Satellite Map">
//             <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
//           </LayersControl.Overlay>
//           <LayersControl.Overlay name="Esri Satellite Map">
//             <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
//           </LayersControl.Overlay>
//         </LayersControl>
//       </MapContainer>
//       <div>
//         {data.images.map((imageUrl, index) => {
//           let label = "Unknown"; // Default label

//           if (imageUrl.includes("VV")) {
//             label = "VV";
//           } else if (imageUrl.includes("VH")) {
//             label = "VH";
//           } else if (imageUrl.includes("Water")) {
//             label = "Water";
//           }

//           return (
//             <label key={index}>
//               <input
//                 type="radio"
//                 value={index}
//                 checked={index === selectedOverlay}
//                 onChange={handleOverlayChange}
//               />
//               {` ${label}`}
//             </label>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// export default DayReachMap;