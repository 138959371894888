// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 12px;
}
.water-pixels {
  color: #0047AB;
  font-weight: bold;
}
.LineChart {
  font-family: Arial, sans-serif;
}
.recharts-xAxis-tick {
  font-size: 14px;
  fill: #333;
}
.recharts-yAxis-tick {
  font-size: 14px;
  fill: #333;
}
.recharts-cartesian-grid {
  stroke: #eee;
}
.recharts-line {
  stroke-width: 2;
}

/* .custom-tooltip {
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
   
  .water-pixels {
    color: #0047AB;
  }
  .tooltip-image {
    width: 225px;
    height: auto;
    margin-right: 5px;
  } */`, "",{"version":3,"sources":["webpack://./src/components/chart/Chart.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,eAAe;AACjB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,UAAU;AACZ;AACA;EACE,eAAe;EACf,UAAU;AACZ;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB;;AAEA;;;;;;;;;;;;;;KAcK","sourcesContent":[".custom-tooltip {\n  background-color: #ffffff;\n  border: 1px solid #ddd;\n  border-radius: 6px;\n  padding: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  font-size: 12px;\n}\n.water-pixels {\n  color: #0047AB;\n  font-weight: bold;\n}\n.LineChart {\n  font-family: Arial, sans-serif;\n}\n.recharts-xAxis-tick {\n  font-size: 14px;\n  fill: #333;\n}\n.recharts-yAxis-tick {\n  font-size: 14px;\n  fill: #333;\n}\n.recharts-cartesian-grid {\n  stroke: #eee;\n}\n.recharts-line {\n  stroke-width: 2;\n}\n\n/* .custom-tooltip {\n    background-color: white;\n    border: 1px solid #ccc;\n    padding: 5px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n   \n  .water-pixels {\n    color: #0047AB;\n  }\n  .tooltip-image {\n    width: 225px;\n    height: auto;\n    margin-right: 5px;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
